import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DsaModel} from '@matchsource/models/dsa';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class DsaApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getAll(): Observable<DsaModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingDsaData);

    return this.nomenclaturesControllerService.getAntigenAndAlleles(null, {context: context()});
  }
}
