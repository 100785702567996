import {ShippingAddress} from '@matchsource/models/related-family-members';

export class AddShippingAddress {
  static readonly type = '[RelatedFamilyMembersAddresses] Update Shipping Addresses List';

  constructor(public readonly shippingAddress: ShippingAddress) {}
}

export class ClearShippingAddressesList {
  static readonly type = '[RelatedFamilyMembersAddresses] Clear Shipping Addresses List';
}
