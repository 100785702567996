import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {append, patch} from '@ngxs/store/operators';

import {AddShippingAddress, ClearShippingAddressesList} from './related-family-members-addresses.actions';
import {ShippingAddress} from '@matchsource/models/related-family-members';

export interface RelatedFamilyMembersAddressesStateModel {
  shippingAddresses: ShippingAddress[];
}

const initState = (): RelatedFamilyMembersAddressesStateModel => ({
  shippingAddresses: [],
});

@State<RelatedFamilyMembersAddressesStateModel>({
  name: 'relatedFamilyMembersAddressesStateModel',
  defaults: initState(),
})
@Injectable()
export class RelatedFamilyMembersAddressesState {
  @Selector([RelatedFamilyMembersAddressesState])
  static shippingAddresses(state: RelatedFamilyMembersAddressesStateModel): ShippingAddress[] {
    return state.shippingAddresses;
  }

  @Action(AddShippingAddress)
  updateShippingAddressesList(
    ctx: StateContext<RelatedFamilyMembersAddressesStateModel>,
    {shippingAddress}: AddShippingAddress
  ) {
    ctx.setState(
      patch<RelatedFamilyMembersAddressesStateModel>({
        shippingAddresses: append<ShippingAddress>([shippingAddress]),
      })
    );
  }

  @Action(ClearShippingAddressesList)
  clearShippingAddresses(ctx: StateContext<RelatedFamilyMembersAddressesStateModel>) {
    ctx.patchState(initState());
  }
}
